
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

@import "~dropzone/src/dropzone";

$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;
@import "~font-awesome/scss/font-awesome.scss";

@import "variables";

@import "~bootstrap-sass/assets/stylesheets/bootstrap";

@import "~quill/dist/quill.snow";

.flexbox-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ql-snow {
  background-color: #fff;
}

.table-white {
  background-color: #fff;
}

.table-bordered-outside {
  border: 1px solid #ddd;
}

.dropzone {
  border-style: dashed;

  .dz-message {
    font-size: 2em;
  }
}

.media {
  overflow: visible;
}

.media-object {
  width: 150px;

  &.media-object-small {
    width: 130px;
  }
}

.large-icon {
  font-size: 63px;
}